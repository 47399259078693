<template>
  <div class="v--modal-overlay delivery-info-overlay">
    <div class="v--modal-background-click">
      <div class="v--modal-box v--modal">
        <div>
          <div class="popup-content">
            <div class="popup-body p-10">
              <div class="popup-title delivery-logo">
              </div>
              <div class="delivery-title">{{ $t("contact_less_delivery_title") }}</div>
              <div class="delivery-heading" style="">{{ $t("contact_less_delivery_heading") }}</div>
              <div>
                <div class="row h-100" style="margin: 10px 0px;">
                  <div class="col-xs-4 col-md-3 px-0">
                    <img src="../../assets/images/icons/icon_call.jpg">
                  </div>
                  <div class="col-xs-8 col-md-9 pt-5" style="position: relative;">
                    <p>{{ $t("contact_less_delivery_arrives") }}</p>
                  </div>
                </div>
                <div class="row h-100" style="margin: 10px 0px;">
                  <div class="col-xs-4 col-md-3 px-0">
                    <img src="../../assets/images/icons/icon_product_placement.jpg">
                  </div>
                  <div class="col-xs-8 col-md-9 pt-5" style="position: relative;">
                    <p>{{ $t("contact_less_delivery_placed") }}</p>
                  </div>
                </div>
                <div class="row h-100" style="margin: 10px 0px;">
                  <div class="col-xs-4 col-md-3 px-0">
                    <img src="../../assets/images/icons/icon_distance.jpg">
                  </div>
                  <div class="col-xs-8 col-md-9 pt-5" style="position: relative;">
                    <p>{{ $t("contact_less_delivery_picked") }}</p>
                  </div>
                </div>
              </div>
              <div class="popup-more-info">
                <span class="delivery-info" v-html="$t('contact_less_delivery_links')"></span>
              </div>
            </div>
            <div class="modal-action">
              <button @click="closePopUp" class="btn btn-lg btn-success full  js-action-btn-add proceed-to-order">
                {{ $t("contact_less_delivery_proceed") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['outlet'],
  methods: {
    closePopUp: function () {
      let body = document.body
      body.getElementsByClassName('delivery-info-overlay')[0].classList.remove('open')
    }
  }
}
</script>
<style scoped>
  .popup-content {
    border-radius: 5px;
    background-color: white;
    margin: 4% auto;
    display: flex;
    flex-direction: column;
    width: 24%;
    overflow: auto;
    min-width: 351px;
  }
  .popup-body {
    height: 520px;
  }
  .popup-title {
    height: 100px;
    margin: 0 auto auto;
  }
  .popup-more-info {
    margin-top: 24px;
    text-align: center;
  }
  @media (max-width: 860px) {
    .popup-content {
      display: block;
      margin: 15px 0;
      width: 370px;
      max-height: 100vh;
      overflow: scroll;
      margin: 0 auto;
    }
  }
  @media (max-width: 500px) {
    .popup-content {
      display: block;
      margin: 16% auto;
      width: calc(100vw - 8%);
      overflow: scroll;
    }
    .popup-title {
      height: 80px;
      margin: 0 auto;
    }
    .modal-action {
      width: calc(100vw - 27%);
    }
    * {
      font-size: 11px;
    }
    .popup-more-info{
      margin-top: 20px;
    }
  }
  @media (max-width: 350px) {
    .popup-content {
      margin: 15% auto;
      width: calc(100vw - 4%);
      min-width: calc(100vw - 4%);
      overflow: scroll;
    }
    .popup-title {
      margin: 0 auto;
    }
    .popup-more-info {
      margin: 0;
      margin-top: 13px;
    }
    .modal-action {
      padding-top: 0 !important;
    }
  }
  .delivery-title {
    text-align: center;
    color: #808080;
  }
  .delivery-heading {
    margin: 10px 75px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }
  .delivery-info {
    color: black;
    text-align: center;
    font-weight: 100;
  }
  .proceed-to-order {
    border-bottom-style: outset;
    text-transform: uppercase;
    display: block;
    margin: 0 auto;
  }
  .modal-action {
    margin: 0 auto;
    padding: 10px;
    width: 100%;
    padding-top: 0px;
  }
</style>
